<template>
  <div id="app">

    <!-- 注销导航栏，默认访问home.vue -->
    <!--
      <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> |
      <router-link to="/menuc">menuc</router-link> |
      <router-link to="/new">new</router-link>
    </nav> -->

    <router-view/>
   
  </div>
</template>


<script>
export default {
  methods: {
    goToAbout() {
      this.$router.push('/about');
    },
    navigateTo(route) {
      this.$router.push(route, { replace: true });
    },
  },
};
</script>
<style>
#app {
  position: absolute;
  display: flex;
  top: 0px;
  left: 0px;
  margin: 0;
  width: 100%; /* 设置宽度为100% */
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}


</style>
